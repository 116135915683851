import './App.css';

import React, { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { PLAID } from './constants'

function App() {
  const onSuccess = useCallback((token, metadata) => {
    console.log('token', token)
    console.log('metadata', metadata)
  }, []);
 
  const config = {
    clientName: 'Flybits',
    env: PLAID.ENV,
    product: PLAID.PRODUCTS.split(',').filter(i => i),
    publicKey: PLAID.PUBLIC_KEY,
    onSuccess,
  };
 
  const { open, ready } = usePlaidLink(config);
  return (
    <div className="App">
      <button onClick={() => open()} disabled={!ready}>
        Connect a bank account
      </button>
    </div>
  );
}

export default App;
